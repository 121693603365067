<template>
  <v-container fluid fill-height>
    <v-card elevation="16" rounded width="100%" height="100%">
      <v-card-title class="text-center">Apple Tasting</v-card-title>
      <v-btn
        id="getDataButton"
        color="primary"
        style="z-index: 100"
        absolute
        top
        right
        @click="getData()"
      >
        Get New Apple Data
      </v-btn>
      <v-btn
        id="popupButton"
        color="primary"
        style="z-index: 100"
        absolute
        bottom
        right
        @click="showPopup()"
      >
        SweetAlert2 Popup
      </v-btn>
      <v-row style="height: 100%">
        <v-col>
          <AgGridVue
            style="height: 100%; width: 100%"
            :grid-options="gridOptions"
            :row-data="rowData"
            :column-defs="columnDefs"
            class="ag-theme-material"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import "ag-grid-enterprise"

export default {
  name: "App",
  components: {
    AgGridVue,
  },
  data: () => ({
    gridApi: null,
    columnApi: null,
    rowData: [
      { name: "Rotten", deliciousness: 1.5 },
      { name: "Wormy", deliciousness: 2 },
      { name: "Soggy", deliciousness: 3 },
    ],
    columnDefs: [{ field: "name", rowGroup: true }, { field: "deliciousness" }],
    gridOptions: {},
  }),
  methods: {
    getData() {
      this.$api.get("/types").then((response) => {
        this.rowData = response.data
      })
    },
    showPopup() {
      this.$alert.fire({
        title: "Warning",
        text: `The British are coming!`,
        icon: "error",
        position: "center",
      })
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api
      this.columnApi = this.gridOptions.columnApi
    },
  },
}
</script>
